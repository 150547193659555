import { FunctionComponent } from "react";
import React, { useState } from "react";

type AccordionItemProps = {
  title: string;
  children: React.ReactNode;
};

const AccordionItem: FunctionComponent<AccordionItemProps> = ({
  title,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="my-2 text-left">
      <div
        className="flex justify-between items-center p-4 bg-gray-200 cursor-pointer"
        onClick={() => setOpen(!isOpen)}
      >
        <h2 className="font-bold text-[2rem]">{title}</h2>
        <div className="text-xl text-[#b8fd4f]">
          {isOpen ? "close -" : "open +"}
        </div>
      </div>
      {isOpen && (
        <div className="px-8 text-[1.5rem] bg-gray-100">{children}</div>
      )}
    </div>
  );
};

const FormContainer1: FunctionComponent = () => {
  return (
    <div className="  text-center text-[35px] md:text-81xl text-white font-pp-telegraf">
      <div>
        <div className="">
          <div className=" leading-[41px] capitalize text-white font-pp-telegraf md:mt-40 my-20">
            our clients
          </div>
        </div>
      </div>
      <div className="text-[14px] md:grid grid-cols-4 gap-8">
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className=" w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)]  rounded-3xs  object-cover"
            alt="apelist"
            src="/rectangle-45@2x.png"
          />
          <span>Apelist</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt="gas"
            src="/rectangle-49@2x.png"
          />
          <span>Gangster All Star</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt="ss"
            src="/rectangle-46@2x.png"
          />
          <span>Steady Stack</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt="helix"
            src="/rectangle-50@2x.png"
          />
          <span>Helix</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt="collision"
            src="/rectangle-54@2x.png"
          />
          <span>Collider Craftworks</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt="seizon"
            src="/rectangle-47@2x.png"
          />
          <span>Seizon</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)]  border-[1px] border-solid border-gray"
            alt="pg"
            src="/big3.png"
          />
          <span>BIG3</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt="apiens"
            src="/rectangle-57@2x.png"
          />
          <span>Apiens</span>
        </div>

        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className="w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt="zooverse"
            src="/rectangle-48@2x.png"
          />
          <span>Zooverse</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className=" w-full rounded-3xs object-cover border-[1px] border-solid border-gray"
            alt=""
            src="/logo_dfa_twitter.jpg"
          />
          <span>DFA</span>
        </div>
        <div className="transition-all ease-out hover:translate-y-1 mb-10 md:mb-2 flex flex-col md:space-y-2 space-y-4">
          <img
            className=" w-full shadow-[0_9px_0_rgb(0,0,0)] hover:shadow-[0_4px_0px_rgb(0,0,0)] "
            alt=""
            src="/rectangle-56@2x.png"
          />
          <span>Fusion XYZ</span>
        </div>

        <div className="mb-10">
          <div className="flex items-center justify-center rounded-181xl box-border w-[315.6px] h-[300px] border-[1px] border-solid border-lawngreen-100 mx-auto">
            <div className="leading-[109%] capitalize text-[30px]"> you?</div>
          </div>
        </div>
      </div>
      {/* <div className="">
        <div className="mt-40 mb-20 leading-[109%] capitalize">
          <p className="m-0">
            <span className="font-pp-telegraf">
              Got <i className="font-pp-writer">questions</i>
            </span>

            <span>?</span>
          </p>
          <p className="m-0">We are here to help.</p>
        </div>
      </div>
      <div className="text-21xl">
        <div className="">
          <AccordionItem title="Who is Webthree Consulting & Auditing?">
            <p>Your answer to this question goes here...</p>
          </AccordionItem>
          <AccordionItem title="What is Alphabot?">
            <p>Your answer to this question goes here...</p>
          </AccordionItem>
        </div>
      </div> */}
    </div>
  );
};

export default FormContainer1;
