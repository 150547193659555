import { FunctionComponent } from "react";

const openLink = (url: string) => {
  window.open(url, "_blank");
};

const FormContainer: FunctionComponent = () => {
  return (
    <div className="text-center md:text-left text-[40px] md:text-181xl text-black font-pp-telegraf ">
      <div className="rounded-26xl box-border md:h-[697px] border-[1.5px] border-solid border-lawngreen-100 relative z-[99] bg-black">
        <div className="w-full mt-10  md:w-[260px] text-mini md:absolute top-8 left-8 px-5 box-border">
          <div className="text-[#63ff1e] md:inline-block md:w-[260px] md:h-[109px]">
            Our mission is to help ensure the integrity of the nft space
            continues to thrive by providing clients with a full suite of
            services to assist them with the launch of their projects.
          </div>
          {/* <div className="w-[23px] h-4 text-center font-pp-writer">
            <div className="text-[#63ff1e]">
              <i>[</i>
              <span>{`&`}</span>
              <i>]</i>
            </div>
          </div> */}
        </div>

        <div className="md:flex justify-between items-center max-w-[1200px] mx-auto md:mt-[350px] mt-10 mb-10 relative z-[999999] ">
          <div className="text-[#63ff1e]">I’m In</div>
          <div className="rounded-81xl text-center [background:linear-gradient(140.19deg,_#b8fd4f,_#63ff1e)] box-border md:w-[464px] md:max-w-none md:h-[62px] overflow-hidden text-[20px] max-w-[250px] my-2 mx-auto md:text-21xl border-[1px] border-solid border-lawngreen-200">
            <div
              className="capitalize cursor-pointer"
              onClick={() =>
                openLink(
                  "https://docs.google.com/forms/d/1y9DK6uTjZ47iwnobBdbTm5c5ToTVDcM5kIftlAZs9o0/edit?ts=655f4ab2"
                )
              }
            >
              Get started
            </div>
          </div>
        </div>
        <img
          className="hidden md:block absolute overflow-hidden top-0 right-0"
          alt=""
          src="/frame-52.svg"
        />
      </div>

      <div className="text-center text-white relative">
        <img
          className="absolute w-full h-[333.53px] -rotate-45 top-0 -left-[50%] z-0"
          alt=""
          src="/vector1.svg"
        />
        <div className="text-[30px] md:text-[114px] leading-[114%] uppercase mb-[100px] relative z-[999] pt-[200px]">
          webthree & alphabot
        </div>
        <img
          className="w-full  h-[299.04px] absolute bottom-[-200px] -left-[20%] xl:-left-[28%] lg:bottom-[-100px] "
          alt=""
          src="/vector2.svg"
        />
      </div>
    </div>
  );
};

export default FormContainer;
