import { FunctionComponent } from "react";
import FormContainer from "../components/FormContainer";
import WhyUsContainer from "../components/WhyUsContainer";
import TeamCard from "../components/TeamCard";
import FormContainer1 from "../components/FormContainer1";
import CustomDevelopmentDropContainer from "../components/CustomDevelopmentDropContainer";
import Container from "../components/Container";
import React, { useState, useEffect, useRef } from "react";

const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

const WEBTHREEALPHABOT: FunctionComponent = () => {
  const [videoEnded, setVideoEnded] = useState(isMobileDevice);
  const containerRef = useRef(null);
  const customDevRef = useRef(null);
  const whyUsRef = useRef(null);
  const teamRef = useRef(null);
  const formRef = useRef(null);
  const formRef1 = useRef(null);

  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  useEffect(() => {
    if (!isMobileDevice()) {
      const video = document.getElementById(
        "site-intro-video"
      ) as HTMLVideoElement;
      if (video) {
        video.play();
      }
    }
  }, []);

  const scrollToComponent = (ref: {
    current: {
      scrollIntoView: (arg0: { behavior: string; block: string }) => void;
    };
  }) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <div className="relative bg-black w-full  overflow-hidden text-left text-mini text-white font-pp-telegraf">
      <div
        className={`bg-black fixed flex text-white w-[100%] py-4 border-white  border-solid border-b z-[9999] justify-between items-center px-8 box-border ${
          videoEnded ? "opacity-100 visible" : "opacity-0 hidden"
        }`}
      >
        <div>
          <img className="w-full max-w-[150px]" alt="" src="/group-183.svg" />
        </div>
        <div className="hidden md:flex justify-between items-center w-full text-[22px]  max-w-[600px] mx-auto">
          <a
            //@ts-ignore
            onClick={() => scrollToComponent(containerRef)}
            className="text-white no-underline cursor-pointer"
          >
            HOME
          </a>
          <a
            //@ts-ignore
            onClick={() => scrollToComponent(customDevRef)}
            className="text-white no-underline cursor-pointer"
          >
            SERVICE
          </a>
          <a
            //@ts-ignore
            onClick={() => scrollToComponent(whyUsRef)}
            className="text-white no-underline cursor-pointer"
          >
            WHY US
          </a>
          <a
            //@ts-ignore
            onClick={() => scrollToComponent(formRef)}
            className="text-white no-underline cursor-pointer"
          >
            CLIENTS
          </a>
          <a
            //@ts-ignore
            onClick={() => scrollToComponent(teamRef)}
            className="text-white no-underline cursor-pointer"
          >
            TEAM
          </a>
        </div>
        <div className="max-w-[150px] w-full text-right md:text-inherit">
          <img
            src="https://assets-global.website-files.com/625416e0a64e0c6895f79107/626fcc6252006a9d0186c6c3_Twitter-icon.svg"
            alt=""
            className="max-w-[30px] "
            onClick={() => {
              window.open("https://twitter.com/Web3Auditing", "_blank");
            }}
          />
        </div>
      </div>
      <div className="relative">
        {!videoEnded && (
          <div className="bg-black absolute w-full h-screen overflow-hidden z-[9999] -mt-[200px]">
            <video
              id="site-intro-video"
              src="/Ohh.mp4"
              autoPlay
              muted
              onEnded={handleVideoEnd}
              className="absolute w-full h-full"
            />
          </div>
        )}
        <div
          className={`transition-opacity duration-500 max-w-[80%] px-4 mx-auto ${
            videoEnded ? "opacity-100" : "opacity-0"
          }`}
        >
          <div ref={containerRef}>
            <Container />
          </div>
          <div ref={customDevRef}>
            <CustomDevelopmentDropContainer />
          </div>
          <div ref={whyUsRef}>
            <WhyUsContainer />
          </div>
          <div ref={formRef}>
            <FormContainer1 />
          </div>

          <div ref={teamRef}>
            <TeamCard />
          </div>

          <div>
            <FormContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WEBTHREEALPHABOT;
