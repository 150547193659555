import { FunctionComponent } from "react";

const WhyUsContainer: FunctionComponent = () => {
  return (
    <div className="text-center text-[35px] md:text-81xl text-white font-pp-telegraf">
      <div className="font-pp-writer">
        <div className="mt-40 leading-[109%] capitalize">
          <i>Why</i>
          <span className="font-pp-telegraf"> us?</span>
        </div>
        {/* <div className=" text-xl leading-[109%] lowercase font-pp-telegraf">
          about
        </div> */}
      </div>

      <p className=" leading-[114%] inline-block text-xl max-w-[850px] lg:text-[1.5rem]">
        Our goal is to champion the ongoing success and integrity of the NFT
        space. We achieve this by offering a comprehensive range of services
        tailored to support our clients in launching and elevating their
        projects.
      </p>

      <div className="md:grid grid-cols-2  gap-[110px] md:text-41xl mb-40">
        <div className="max-w-[500px]">
          <video
            src="/web3small.mp4"
            autoPlay={true}
            muted
            loop
            className="hidden md:block"
          ></video>
          <img src="/videogif.gif" className="md:hidden" alt="" />
        </div>
        <div className="text-[20px] md:text-[35px] text-left">
          <div>
            <div>
              <div className=" top-[-0.5px] left-[calc(50%_-_460.5px)] box-border w-[921px] h-px border-t-[1px] border-solid border-gray" />
              <div className=" text-[20px] uppercase leading-[114%] text-[#63ff1e] my-8">
                Experience
              </div>
              <div className="  leading-[114%] capitalize">100+ mints</div>
            </div>
          </div>
          <div>
            <div className="">
              <div className=" top-[-0.5px] left-[calc(50%_-_460.5px)] box-border w-[921px] h-px border-t-[1px] border-solid border-gray" />
              <div className=" text-[20px] uppercase leading-[114%] text-[#63ff1e] my-8">
                Our results
              </div>
              <div className=" top-[35px] left-[calc(50%_-_200px)] leading-[109%] capitalize">
                300+ Audits
              </div>
            </div>
          </div>
          <div>
            <div className="">
              <div className=" top-[-0.5px] left-[calc(50%_-_460.5px)] box-border w-[921px] h-px border-t-[1px] border-solid border-gray" />
              <div className=" text-[20px] uppercase leading-[114%] text-[#63ff1e] my-8">
                We don't make mistakes
              </div>
              <div className="  leading-[114%] capitalize">O failed mints</div>
            </div>
          </div>
          <div>
            <div className=" top-[27px] left-[calc(50%_-_463px)] w-[926px] ">
              <div className=" top-[-0.5px] left-[calc(50%_-_463.5px)] box-border w-[921px] h-px border-t-[1px] border-solid border-gray" />
              <div className=" text-[20px] uppercase leading-[114%] text-[#63ff1e] my-8">
                Resource allocation
              </div>
              <div className=" top-[32px] left-[calc(50%_-_203px)] leading-[114%] capitalize">
                3 devs for every project
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img
        className=" top-[183.88px] left-[280px] w-[650.38px] h-[925.25px] object-cover"
        alt=""
        src="/telegramclouddocument25465451557535362043-1@2x.png"
      />
      <img
        className=" top-[862.88px] left-[280px] w-[297.21px] h-[375.85px] object-cover"
        alt=""
        src="/telegramclouddocument25465451557535362043-2@2x.png"
      /> */}
    </div>
  );
};

export default WhyUsContainer;
