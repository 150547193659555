import React, { useState, useEffect, useRef } from "react";
import { FunctionComponent } from "react";

const TeamCard = () => {
  const [animationProgress, setAnimationProgress] = useState(0);
  const [isInViewport, setIsInViewport] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const animationEndThreshold = 5000;

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsInViewport(entry.isIntersecting);
        });
      },
      {
        root: null,
        rootMargin: "0px 0px -700px 0px",
        threshold: 0.01,
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      console.log(animationProgress, "animationProgress");
      if (isInViewport) {
        // event.preventDefault();
        // setAnimationProgress((prev) => prev + event.deltaY * 5);
        console.log("in viewport");
      }
    };

    if (animationProgress >= animationEndThreshold) {
      console.log("animationProgress >= animationEndThreshold");
      console.log(animationProgress, "animationProgress");
      console.log(animationEndThreshold, "animationEndThreshold");
      setIsInViewport(false);
    }

    if (isInViewport) {
      window.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      window.removeEventListener("wheel", handleScroll);
    }

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [isInViewport, animationProgress]);

  const calculateStyle = (
    baseRotation: any,
    xposition: any,
    yposition: any,
    originX = "50%",
    originY = "50%"
  ) => {
    // Use animationProgress instead of scrollY
    const rotation = baseRotation + animationProgress * 0.1;
    const xPos = xposition + animationProgress * 0.1;
    const ypos = yposition + animationProgress * 0.1;
    const scale = 1 + animationProgress * xposition;
    const translateValue = !isInViewport
      ? "translateX(-50%) translateY(-50%)"
      : `translate(${xPos}%,${ypos}%) rotate(${rotation}deg) `;
    return {
      transition: "transform 0.7s ease-out",
      transform: translateValue,
      transformOrigin: `${originX} ${originY}`,
    };
  };

  return (
    <>
      <div className="hidden md:block text-81xl leading-[109%] capitalize text-white text-center">
        <p className="mt-5 mb-0">
          Meet the{" "}
          <span className="m-0 font-pp-writer">
            <i>team</i>
            <span className="font-pp-neue-montreal">{` `}</span>
          </span>
        </p>
      </div>
      <div
        ref={componentRef}
        className="relative text-left text-[12.42px] text-black font-pp-telegraf min-h-[1500px] hidden md:block"
      >
        <div>
          <div
            style={calculateStyle(-30, -220, 100)}
            className=" absolute top-1/4 left-1/2 transform -translate-x-[50%] max-w-[300px]"
          >
            <div className="">
              <div className="relative">
                <img className="max-w-[500px]" alt="" src="/ziggy.jpeg" />
                <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                  <div className="relative uppercase text-[20px]">
                    ziggy . DEV & PARTNER
                  </div>
                  <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
                </div>
              </div>
            </div>
          </div>
          <div
            style={calculateStyle(30, 120, -50)}
            className=" absolute top-1/4 left-1/2 transform -translate-x-[50%] max-w-[300px]"
          >
            <div className="relative">
              <img className="max-w-[500px]" alt="" src="/zooper.jpeg" />
              <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                <div className="relative uppercase text-[20px]">
                  Zooper . DEV & PARTNER
                </div>
                <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
              </div>
            </div>
          </div>

          <div
            style={calculateStyle(-25, -220, -44)}
            className=" absolute top-1/4 left-1/2 transform -translate-x-[50%] max-w-[300px]"
          >
            <div className="relative">
              <img className="max-w-[500px]" alt="" src="/hmm.png" />
              <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                <div className="relative uppercase text-[20px]">
                  MR_HMMM . CEO & FOUNDER
                </div>
                <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
              </div>
            </div>
          </div>
          <div
            style={calculateStyle(30, 50, 50)}
            className=" absolute top-1/4 left-1/2 transform -translate-x-[50%] max-w-[300px]"
          >
            <div className="relative">
              <img className="max-w-[500px]" alt="" src="/cris.png" />
              <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                <div className="relative uppercase text-[20px]">
                  Cristhian . DEV & PARTNER
                </div>
                <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="md:hidden relative text-left text-[12.42px] text-black font-pp-telegraf min-h-[1500px]">
        <div>
          <div className=" max-w-[150px]">
            <div className="">
              <div className="relative">
                <img className="max-w-[300px]" alt="" src="/ziggy.jpeg" />
                <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                  <div className="relative uppercase text-[20px]">
                    ziggy . DEV & PARTNER
                  </div>
                  <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
                </div>
              </div>
            </div>
          </div>
          <div className=" max-w-[150px]">
            <div className="relative">
              <img className="max-w-[300px]" alt="" src="/zooper.jpeg" />
              <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                <div className="relative uppercase text-[20px]">
                  Zooper . DEV & PARTNER
                </div>
                <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
              </div>
            </div>
          </div>

          <div className="max-w-[150px]">
            <div className="relative">
              <img className="max-w-[300px]" alt="" src="/hmm.png" />
              <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                <div className="relative uppercase text-[20px]">
                  MR_HMMM . CEO & FOUNDER
                </div>
                <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
              </div>
            </div>
          </div>
          <div className="max-w-[150px]">
            <div className="relative">
              <img className="max-w-[300px]" alt="" src="/cris.png" />
              <div className="absolute bottom-0 bg-lawngreen-100 text-black  text-center w-[500px]">
                <div className="relative uppercase text-[20px]">
                  Cristhian . DEV & PARTNER
                </div>
                <div className="relative rounded-[50%] bg-black w-[2.48px] h-[2.48px]" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TeamCard;
