import { FunctionComponent } from "react";

const Container: FunctionComponent = () => {
  return (
    <div className="relative text-center text-xl text-white font-pp-telegraf mb-20 mt-32 px-4">
      <div className="text-mini absolute bottom-0 right-8"></div>
      <img className="w-full" alt="" src="/group-183.svg" />

      <a
        href="https://docs.google.com/forms/d/1y9DK6uTjZ47iwnobBdbTm5c5ToTVDcM5kIftlAZs9o0/edit?ts=655f4ab2"
        target="_blank"
        className="no-underline mt-14 py-2.5 relative  group overflow-hidden font-medium bg-black-50 border-lawngreen-100 text-lawngreen-100 border-2 border-solid rounded-full inline-block px-10"
      >
        <span className="absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 bg-lawngreen-100 text-black group-hover:h-full opacity-90"></span>
        <span className="relative group-hover:text-black">
          Lets' Get Started
        </span>
      </a>
    </div>
  );
};

export default Container;
