import { FunctionComponent } from "react";
import Marquee from "react-fast-marquee";

const CustomDevelopmentDropContainer: FunctionComponent = () => {
  return (
    <div className="text-center text-21xl text-white font-pp-telegraf">
      <div className="text-[40px] md:text-81xl font-pp-writer">
        <div className="leading-[109%] capitalize md:mt-40 md:mb-20">
          <i>What</i>
          <span className="font-pp-telegraf"> we do?</span>
        </div>
        {/* <div className=" top-[63px] left-[calc(50%_-_430px)] text-xl leading-[109%] lowercase font-pp-telegraf">
          services
        </div> */}
      </div>
      <div className="">
        <div className="md:grid md:grid-cols-3 md:gap-4 2xl:gap-32  justify-center md:justify-normal">
          <div className="relative rounded-21xl border-gray border-2 border-solid h-[542px] overflow-hidden my-10">
            <div className="pt-0">
              <i className="text-mini leading-[109%] capitalize inline-block font-pp-writer text-white">
                [01]
              </i>
              <div className="">
                <div className="font-bold text-[20px] md:text-[30px] shadow-2xl leading-[109%] uppercase text-white inline-block  ">
                  <p className="m-0">Custom</p>
                  <p className="m-0">Development/Drop</p>
                </div>

                <div className="absolute bottom-20 -rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-md">
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[24px]">Custom Development Drop</p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[24px]">Custom Development Drop</p>
                  </div>
                </div>

                <div className="absolute top-60 left-0 -rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-md">
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[24px]">Custom Development </p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[24px]">Custom Development </p>
                  </div>
                </div>

                <div className="absolute top-60 left-[-10] rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-sm">
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[24px]">Custom Development </p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[24px]">Custom Development </p>
                  </div>
                </div>

                <div className="absolute bottom-0 rotate-6 bg-lawngreen-100 text-black">
                  <Marquee
                    pauseOnClick
                    pauseOnHover
                    speed={150}
                    gradient={false}
                  >
                    <div className="inline-block mr-8">
                      <p className="my-0">
                        Custom Development Drop . Custom Development Drop .
                        Custom Development Drop . Custom Development Drop
                      </p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">
                        Custom Development Drop . Custom Development Drop .
                        Custom Development Drop . Custom Development Drop
                      </p>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
          <div className="relative rounded-21xl border-gray border-2 border-solid h-[542px] overflow-hidden my-10">
            <div className="pt-0">
              <i className="text-mini leading-[109%] capitalize inline-block font-pp-writer text-white">
                [02]
              </i>
              <div className="">
                <div className="font-bold text-[20px] md:text-[30px] shadow-2xl leading-[109%] uppercase text-white inline-block  ">
                  <p className="m-0">Smart</p>
                  <p className="m-0">Contract Audit</p>
                </div>

                <div className="absolute bottom-20 -rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-md">
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[25px]">Smart Contract Audit</p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[25px]">Smart Contract Audit</p>
                  </div>
                </div>

                <div className="absolute top-60 left-0 -rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-md">
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[25px]">Smart Contract Audit</p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[25px]">Smart Contract Audit</p>
                  </div>
                </div>

                <div className="absolute top-40 left-0 -rotate-4 bg-lawngreen-200 text-black w-[200%] flex blur-sm">
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[25px]">Smart Contract Audit</p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0 text-[25px]">Smart Contract Audit</p>
                  </div>
                </div>

                <div className="absolute -rotate-12 bottom-20 bg-lawngreen-100 text-black">
                  <Marquee
                    pauseOnClick
                    pauseOnHover
                    speed={150}
                    gradient={false}
                  >
                    <div className="inline-block mr-8">
                      <p className="my-0">
                        Smart Contract Audit . Smart Contract Audit . Smart
                        Contract Audit
                      </p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">
                        Smart Contract Audit . Smart Contract Audit . Smart
                        Contract Audit
                      </p>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
          <div className="relative rounded-21xl border-gray border-2 border-solid h-[542px] overflow-hidden my-10">
            <div className="pt-0">
              <i className="text-mini leading-[109%] capitalize inline-block font-pp-writer text-white">
                [03]
              </i>
              <div className="">
                <div className="font-bold text-[20px] md:text-[30px] shadow-2xl leading-[109%] uppercase text-white inline-block  ">
                  <p className="m-0"></p>
                  <p className="m-0">Consulting & Advisory</p>
                </div>

                <div className="absolute bottom-20 -rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-md">
                  <div className="inline-block mr-8">
                    <p className="my-0">Consulting & Advisory</p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0">Consulting & Advisory</p>
                  </div>
                </div>

                <div className="absolute top-60 left-0 -rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-md">
                  <div className="inline-block mr-8">
                    <p className="my-0">Consulting & Advisory</p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0">Consulting & Advisory</p>
                  </div>
                </div>

                <div className="absolute top-40 left-0 rotate-6 bg-lawngreen-200 text-black w-[200%] flex blur-sm">
                  <div className="inline-block mr-8">
                    <p className="my-0">Consulting & Advisory</p>
                  </div>
                  <div className="inline-block mr-8">
                    <p className="my-0">Consulting & Advisory</p>
                  </div>
                </div>

                <div className="absolute bottom-80 -left-2 -rotate-6 bg-lawngreen-100 text-black">
                  <Marquee speed={150} gradient={false} direction="right">
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                    <div className="inline-block mr-8">
                      <p className="my-0">Consulting & Advisory</p>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDevelopmentDropContainer;
